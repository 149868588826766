import type { UseFocusTrapReturn } from "@vueuse/integrations/useFocusTrap";

export const useInjectFocusTrap = function () {
  const focusTrap = inject<UseFocusTrapReturn | undefined>("focusTrap", undefined);

  const handleFocusTrap = function (isElementActive: boolean) {
    if (isElementActive) focusTrap?.pause();
    else focusTrap?.unpause();
  };

  onUnmounted(() => {
    focusTrap?.unpause();
  });

  return {
    handleFocusTrap,
  };
};
